<template>
  <div class="tikuanalysis">
    <!-- title -->
    <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
    <div class="tikuanalysis_title">
      <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          :to="{
            path: '/examcenter',
            query: { secondId: this.secondId, firstId: this.firstId },
          }"
        >在线题库</el-breadcrumb-item>
        <el-breadcrumb-item
          v-if="tabIndex == 3 || tabIndex == 4 || tabIndex == 5"
        >{{ examInfo.testPaperName }}</el-breadcrumb-item>
        <el-breadcrumb-item v-else>{{ examInfo.nodeName }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="tikuanalysis_title_time">
        <img src="@/assets/img/exam/time.png" alt />
       
      </div>-->
    </div>

    <!-- main -->
    <div class="tikuanalysis_main">
      <!-- main_left -->
      <div class="tikuanalysis_main_left" v-show="AnswerList">
        <answerCard ref="answerCard" :AnswerList="AnswerList" @jumpTo="jumpTo"></answerCard>
      </div>

      <!-- main_right -->
      <div class="tikuanalysis_main_right" v-show="AnswerList">
        <div class="tikuTitleBox">
          <span style="cursor:pointer" @click="goTuTikuExam">在线题库 - </span>
          <span>{{questionBank_info.subjectFirstTypeName}}</span>
          <span v-if="questionBank_info.subjectSecondTypeName">/{{questionBank_info.subjectSecondTypeName}}</span>
          <span v-if="questionBank_info.subjectChapterName">/{{questionBank_info.subjectChapterName}}</span>
          <span v-if="questionBank_info.subjectNodeName">/{{questionBank_info.subjectNodeName}}</span>
          <span v-else>/{{examInfo.testPaperName}}</span>
        </div>
        <questionArea
          :AnswerList="AnswerList"
          ref="questionArea"
          @allEventFn="allEventFn"
          @changePaper="changePaper"
        ></questionArea>
      </div>
    </div>
    <t_footer></t_footer>
  </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import "./scss/tikuAnalysis.scss"; //引入scss
import answerCard from "./components/answerCard";
import questionArea from "./components/questionArea";
import { Loading } from "element-ui";
// import {
//   subject_list,
//   save_subject,
//   add_sign,
//   add_collect,
//   deleted_collect,
//   assignment_paper,
//   subjectPaperList,
//   savePaper_subject,
//   assignment_exercise,
//   error_exercise_subjectList,
//   error_paper_subjectList,
//   analysis_list,
//   analysisList_new,
//   analysis_new,
//   subject_analysisList
// } from "@/service/apiService/exam.js";
import {
  subject_list,
  save_subject,
  add_sign,
  add_collect,
  deleted_collect,
  assignment_paper,
  subjectPaperList,
  savePaper_subject,
  assignment_exercise,
  error_exercise_subjectList,
  error_paper_subjectList,
  analysis_list,
  analysis_new,
  analysisList_new
} from "@/api/exam";
import {
  subject_analysis,
  questionStatistics
} from "@/api/exam";
export default {
  name: "tikuanalysis",
  components: {
    answerCard,
    questionArea,
    t_header,
    t_footer,
  },
  data() {
    return {
      isUserInfo: false,
      AnswerList: [],
      hour: 0, //定义时，分，秒，毫秒并初始化为0；
      minute: 0,
      second: 0, //秒
      time: "",
      str: "",
      second_time: 0,
      secondId: "",
      firstId: "",
      tabIndex: "",
      twoIindex: "",
      examInfo: {},
      testSecond_time: 0,
      answerTimers: "",
      paperAnswerTime: "",
      questionBank_info: {},
      
    };
  },
  created() {
    // this.loading = Loading.service({
    //   text: "Loading...",
    //   background: "rgba(0, 0, 0, 0.1)"
    // });
  },
  mounted() {
    this.tabIndex = this.$route.query.tabIndex;
    this.twoIindex = this.$route.query.twoIindex;
    this.num = this.$route.query.num;
    this.isError = this.$route.query.isError;
    this.nodeType = this.$route.query.nodeType;
    this.testPaperId = this.$route.query.testPaperId;
    this.nodeId = this.$route.query.nodeId;
    var testReportInfo = localStorage.getItem("testReport");
    
    if (testReportInfo) {
      testReportInfo = JSON.parse(testReportInfo)
      this.examInfo = testReportInfo.examInfo;
      this.secondId = testReportInfo.secondId;
      this.firstId = testReportInfo.firstId;
      
    }
    if (
      this.tabIndex != "" &&
      this.tabIndex != null &&
      this.tabIndex != undefined
    ) {
      this.nodeType = this.tabIndex;
    }
    if (this.num == 1) {
      this.nodeId = this.$route.query.nodeId;
      this.assignment_exercise();
      //this.analysis_list();
      this.analysis_new();
    } else {
      // this.nodeType=2
      this.testPaperId = this.$route.query.testPaperId;
      // this.isTrue=num
      this.assignment_paper();
      //this.subject_analysisList()
      this.analysisList_new();
    }
  },
  watch: {},
  methods: {
    // 父传子
    getTodos(e) {
      this.isUserInfo = e
    },
    goTuTikuExam(){
      this.$router.push({
        path: "/examcenter",
        query:{
          firstId:this.firstId,
          secondId:this.secondId
        }
      });

    },
    // 历年/模拟/考前试卷-交卷or答题报告
    assignment_paper() {
      assignment_paper({
        testPaperId: this.testPaperId,
        terrace: "2"
      })
        .then(res => {
          if (res.data.code == 0) {
            this.questionBank_info = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // 章节和专项练习
    assignment_exercise() {
      var obj = {
        nodeId: this.nodeId,
        nodeType: this.nodeType
      };
      assignment_exercise(obj)
        .then(res => {
          if (res.data.code == 0) {
            this.questionBank_info = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    analysisList_new() {
      // 历年/模拟/考前试卷-题解析列表 -- new

      var that = this;

      analysisList_new({
        testPaperId: that.testPaperId,
        type: that.isError
      })
        .then(res => {
          if (res.data.code == 0) {
            let subjectListData = res.data.data;
            let obj = {
              1: [],
              2: [],
              3: [],
              4: [],
              5: [],
              6: [],
              7: [],
              8: [],
              9: [],
              10: [],
              11: []
            };
            subjectListData.forEach((v, i) => {
              obj[v.classify].push(1);
              v.questionIndex = obj[v.classify].length;
            });
            this.AnswerList = subjectListData;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    analysis_new() {
      var that = this;
      analysis_new({
        nodeId: that.nodeId,
        nodeType: that.nodeType,
        type: that.isError
      })
        .then(res => {
          let subjectListData = res.data.data;
          let obj = {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: []
          };
          subjectListData.forEach((v, i) => {
            obj[v.classify].push(1);
            v.questionIndex = obj[v.classify].length;
          });
          this.AnswerList = subjectListData;
        })
        .catch(error => {
          console.log(error);
        });
    },
    /*答题卡跳转题目*/
    jumpTo(index, subjectList, index1) {
      this.$refs.questionArea.setActiveQuestion(index, subjectList, index1);
    },
    changePaper(index, two) {
      this.$refs.answerCard.jumpTo(index, two);
    },
    getAnswerTimeLength(times) {
      let arys = times.split(":");
      let time1 = parseInt(arys[0]) * 60 * 60;
      let time2 = parseInt(arys[1]) * 60;
      let time3 = parseInt(arys[2]);
      return time1 + time2 + time3;
    },

    setDataItemValue(res) {
      //this.AnswerList.finallyAnswerId = res.subjectId;
      if (
        this.AnswerList.paperSubjectList[res.dataIndex].subjectList &&
        this.AnswerList.paperSubjectList[res.dataIndex].subjectList.length
      ) {
        let numQuestion = this.AnswerList.paperSubjectList[res.dataIndex]
          .subjectList;
        numQuestion.map((item, index) => {
          if (item.subjectId == res.subjectId) {
            if (
              item.classify == "5" ||
              item.classify == "6" ||
              item.classify == "7" ||
              item.classify == "8" ||
              item.classify == "9"
            ) {
              this.AnswerList.paperSubjectList[res.dataIndex].subjectList[
                index
              ].doneAnswer = res.doneAnswer;
            } else if (item.classify == "10") {
              this.AnswerList.paperSubjectList[res.dataIndex].subjectList[
                index
              ].doneAnswerIds = res.answerId;
            } else {
              this.AnswerList.paperSubjectList[res.dataIndex].subjectList[
                index
              ].doneAnswerIds = res.answerId.join(",");
            }
          }
        });
      } else {
        if (res.type === "checkbox") {
          this.AnswerList.paperSubjectList[
            res.dataIndex
          ].doneAnswerIds = res.answerId.join(",");
        }
      }
    },
    allEventFn(res) {
      subject_analysis({ subjectId: res.subjectId }).then(res => {
        Vue.prototype.$resetSetItem('isAnalysInfo',JSON.stringify(res.data.data));
      });
      questionStatistics({ subjectId: res.subjectId }).then((res)=>{
        Vue.prototype.$resetSetItem('isAnalysNewInfo',JSON.stringify(res.data.data));
      })
    },

    update_size() {
      if (
        document.getElementsByClassName("update_font_size") != undefined &&
        document.getElementsByClassName("update_font_size") != null
      ) {
        var sizes = document.getElementsByClassName("update_font_size");

        for (var i = 0; i < sizes.length; i++) {
          if (sizes[i].getElementsByTagName("font")) {
            var classsize = sizes[i].getElementsByTagName("font");

            for (var j = 0; j < classsize.length; j++) {
              classsize[j].setAttribute("size", "4px");
            }
          }
        }
      }
    },

    formatSeconds(value) {
      let res = parseInt(value);
      let h =
        Math.floor(res / 3600) < 10
          ? "0" + Math.floor(res / 3600)
          : Math.floor(res / 3600);
      let m =
        Math.floor((res / 60) % 60) < 10
          ? "0" + Math.floor((res / 60) % 60)
          : Math.floor((res / 60) % 60);
      let s =
        Math.floor(res % 60) < 10
          ? "0" + Math.floor(res % 60)
          : Math.floor(res % 60);

      let str = "";
      str += `${h}:`;
      str += `${m}:`;
      str += s;
      return str;
    },

    toDub(n) {
      //补0操作
      if (n < 10) {
        return "0" + n;
      } else {
        return "" + n;
      }
    }
  }
};
</script>

<style lang="less" scoped>
#update_font_size {
  /deep/ font {
    size: 4px;
  }
}
.tikuTitleBox{
    width: calc(100% - 60px);
    margin-left: 30px;
    height: 60px;
    font-size: 14px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    color: #28313D;
    line-height: 60px;
    border-bottom: 1px solid #E0E6ED;
    text-align: left;
}
</style>
<style>
</style>
